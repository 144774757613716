import { defineAsyncComponent } from "vue"

/*异步获取模板 */
const loadComponent=(name)=>{
     return defineAsyncComponent(() =>
        import('/src/components/home/member/memberModel/'+name+'/'+name+'.vue')
    )
}

/***纪录所有报表 模板 */
export default {
    /**报表模板 */
    components:{},
    /**模板名称 @param name 不区分大小写和- _符号*/
    getComponent(name){
        name=name?.toLocaleLowerCase().replace(/[-_]/g,'');
        
        let component=({
            /**制卡 */
            "businesscardmember":{
                tagName:"business-card-member",
                name:'businessCardMember'
            },
            /**会员办卡 */
            "membershipcard":{
                tagName:"membership-card", 
                name:'membershipCard'
            },
            /**会员充值 */
            "memberrecharge":{
                tagName:"member-recharge", 
                name:'memberRecharge'
            },
            /**卡资产调整 */
            "cardassetchange":{
                tagName:"card-asset-change", 
                name:'cardAssetChange'
            },
            /**卡 挂失 或 停用 */
            "cardlostorstop":{
                tagName:"card-lost-or-stop", 
                name:'cardLostOrStop'
            },
            /**退卡*/
            "cardreturn":{
                tagName:"card-return", 
                name:'cardReturn'
            },
            /**修改密码*/
            "cardpwdmodify":{
                tagName:"card-pwd-modify", 
                name:'cardPwdModify'
            },
            /**修改会员信息*/
            "memberinfomodify":{
                tagName:"member-info-modify", 
                name:'memberInfoModify'
            },
            /**补卡*/
            "cardreissue":{
                tagName:"card-reissue", 
                name:'cardReissue'
            },
            /**绑卡*/
            "cardbind":{
                tagName:"card-bind", 
                name:'cardBind'
            },
            /**卡解绑*/
            "cardreleasebind":{
                tagName:"card-release-bind", 
                name:'cardReleaseBind'
            },
            /**手动核销券*/
            "memberinfocheckcoupon":{
                tagName:"member-info-check-coupon",
                name:'memberInfoCheckCoupon'
            },
            /**会员卡消费明细表*/
            "cardconsumereport":{
                tagName:"card-consume-report", 
                name:'cardConsumeReport'
            },
            /**会员卡充值明细表*/
            "cardrechargereport":{
                tagName:"card-recharge-report", 
                name:'cardRechargeReport'
            },
            /**会员交易汇总表*/
            "carddayreport":{
                tagName:"card-day-report", 
                name:'cardDayReport'
            }
        })[name];
        if(component){
            if(!this.components[component.name]){
                this.components[component.name]=loadComponent(component.name);
            }
            return component.tagName;
        }
        return;
    }
}